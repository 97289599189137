import React, { useState, useEffect } from 'react';
import PubNub from 'pubnub';
import PubChat from './PubChat'; //student chat
import PubInvigChat from './PubInvigChat'; //invig chat
import { PubNubProvider, usePubNub } from 'pubnub-react'; //library
import { getFromSessionStorage, SESSION_STORAGE_KEYS } from '../Util/sessionStorage';


function Pubnub(props) {
	//pubnub.uuid=this.props.uuid;
	 const {id:user_id='',role=''} = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
	// const user_id=JSON.parse(localStorage.getItem('user')).id;
	// const role=JSON.parse(localStorage.getItem('user')).role;
    var pubnub = new PubNub({
	  publishKey: 'pub-c-8b97c538-4aa6-4d7d-b45c-53079d0d5fc4',
	  subscribeKey: 'sub-c-e36f11b8-096c-44cb-a93c-55615728b935',
	  //publishKey: 'pub-c-61d34df3-451e-49c2-bb8f-8d51e9dfdd9e',
	  //subscribeKey: 'sub-c-f3b67394-0ebb-11eb-a0a1-be9072d3ef6c',
	  uuid: user_id+'monitorexam_com'
    });
	
	//sec-c-ODA5OGZlYTEtOGMwMi00NWE3LWIwODctMDY0OTI2ODUwNjk5
	//pubnub.uuid=user_id+"monitorexam_com";
    // console.log(pubnub.uuid);
	if(role!="invig") {
	  return (
		      <PubNubProvider client={pubnub}>
		         <PubChat />
		      </PubNubProvider>
		    );
	} else {
	  return (
		      <PubNubProvider client={pubnub}>
		         <PubInvigChat users={props.users}/>
		      </PubNubProvider>
		    );
	}

}

export default Pubnub
