import React, {
  Component
} from 'react'
import Pubnub from '../Chat/Pubnub'
import VerifyHelp from './VerifyHelp'
import Http from 'axios'
import Base64js from 'base64-js'
import Button from 'react-bootstrap/Button'


/* User Verification process */
class StartTest extends React.Component {
  constructor() {
    super();
    this.state = {
      exam_found: false,
    };
    this.getExam = this.getExam.bind(this);
    Http.defaults.baseURL=window.location.origin;
  }
  render() {
    if(this.state.phase1&&this.state.phase2)
    {
        return( <VerifyHelp />);
    }
    else
        return(

		<p>Collecting information to display</p>);
  }
  
  bufferEncode(value) {
    // return Buffer.from(value).toString('base64');
      return Base64js.fromByteArray(value)
       .replace(/\+/g, "-")
       .replace(/\//g, "_")
       .replace(/=/g, "");
  }
  bufferDecode(value) {
      return Uint8Array.from(atob(value), c => c.charCodeAt(0));
  }

  componentWillMount() {
    var path=window.location.pathname;
  //  const exam = JSON.parse(localStorage.getItem('exam1'));

    path=path.slice(11);
    var decoded= this.bufferDecode(path)
    var decstring=String.fromCharCode.apply(null, decoded);
    //empty is here because if you split by / you'll get ["", "john", "smith"]

    var getObject = function(first, last, key) {
    //You could traverse arguments, which will have every match in an "array"
        return {
           user_id: first,
           exam_id: last,
           key : key
        };
    }
    var userAndTest= getObject.apply(null, decstring.split('/')); //Object {first_name: "john", last_name: "smith"}
    this.state.user_id=userAndTest.user_id;
    this.state.exam_id=userAndTest.exam_id;
    this.setState({key:userAndTest.key});
/*    if (user)
      this.state.user = user;
    else if (this.props.user)
      this.state.user = this.props.user;
    else
      console.log("User not available");
    if (exam)
      this.state.exam = exam;
    else if (this.props.exam)
      this.state.exam = this.props.exam;
    else {
      console.log("Exam not available");
      this.state.exam_found = 0;
    }

    if (this.state.exam_found != 0) {
      this.setState({
        status: true,
        testStarted: false,
        camera_state: this.state.user.camera_state,
        network_state: this.state.user.network_state,
        battery_state: this.state.user.battery_state,
        battery_level: this.state.user.battery_level,
        cred: this.state.user.cred,
        ip: this.state.user.ip,
        captured: this.state.user.captured,
        live_pic: this.state.user.live_pic,
        reg_pic: this.state.user.reg_pic
      });
      //console.log(this.state.user);
      //console.log(this.state.exam);

      //Time passed since exam and left
      const date = Date.parse(this.state.exam.date);
      const time = Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z");
      var now = Date.now();
      var left = (date + time - now) / 60000;

      //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
      left = Math.floor(left);
      console.log("Minutes " + left);
      this.state.left = left;
    }
    */
  }
  //Will be called post rendering
  async componentDidMount() {
	this.getExam();
	this.getUser();
  }
  //Clear the timerID set in ComponentDidUpdate test whether this works or not
  componentWillUnmount() {
  }
  getUser() {
        const url='ext-api/GetUser/' + this.state.user_id; 
        var config = {
            method: 'get',
            url: url,
            headers: {
    		'Accept': 'application/json',
                'Content-Type': 'text/plain',
                'X-Api-Key':  this.state.key 
            },
            //timeout:100 
        };
        Http(config)
            .then(res => {
                const response = res.data;
                this.setState({
                    user: response
                });
                localStorage.setItem('user', JSON.stringify(this.state.user));
                this.setState({
                    phase1: true
                });
		//this.getAssertion();
//                localStorage.setItem('user', JSON.stringify(response));
                //console.log(response);
                //let userStore = createStore(this.state.user);
                //errorMsgElement.innerHTML = "Username and Password Correct";
		//FIDO Support and there is a local key in the system

		if(this.state.user.fido_auth_enabled && this.state.local_key)
	        {
                   Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response=> {
                   })
                   .then(()=> {
			/* Switch to step 2 */
                            document.querySelector('.pro-num.count-step1').classList.remove('active-num');
                            document.querySelector('.pro-num.count-step2').classList.add('active-num');
		   })
                   .catch(error=> {
		    /* Skip security and move to dashboard*/
	            this.setState({status:true});
		   });
                }
		else
		{
		    /* Skip security and move to dashboard*/
	            this.setState({status:true});
                //    localStorage.setItem('user', JSON.stringify(this.state.user));
		}             //opens chat for all students and lets them know
		//Chat Support
                const bc = new BroadcastChannel('lims_channel');
                 bc.postMessage("joined");
                //return <Redirect to="/profile" />
            })
            .catch(function(error) {
                console.log(error);
                //errorMsgElement.classList.remove("badge-outline-info");
                //errorMsgElement.classList.add("badge-outline-warning");
            });
  }

  toLocal = (utctime) => { 
	  var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z")); 
	  x += this.state.user.timezone * 60000; 
	  return (x.toLocaleString().slice(16, 24)); 
  }
  //standard function. Assign value to name
  getExam(event) {
    var api = "exam-api/exam/" + this.state.exam_id;
    var config = {
        method: 'get',
        url: api,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'text/plain',
            'X-Api-Key': this.state.key 
        },
            //timeout:100 
    };
    const response = Http(config)
        .then(response=> {
            this.setState({ exam_found: true, exam: response.data });
		 if((	   Date.parse(this.state.exam.window_start_date) 
			 + Date.parse("1970-01-01T" 
		         + this.state.exam.window_start_time.toString() 
		         + "Z") 
			 + this.state.exam.window_duration * 60000) > (Date.now())) 
		{
            		var x = new Date().toISOString();
            		this.state.exam.date = x.slice(0, 10);
            		this.state.exam.start_time = x.slice(11, 19);
            		//localStorage.setItem('exam1', JSON.stringify(response.data));
            		localStorage.setItem('exam1', JSON.stringify(this.state.exam));
                this.setState({
                    phase2: true
                });
		}
		else {
			alert("You are late for exam. Please logout from here and reschedule from Sunstone site");
			return;
		}
        })
        .catch(error=> {
            //alert("Getting Credentials Failed");
            console.log("Getting Exam Failed");
        });
    //console.log(response.data);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ exam_found: 1 })
    this.getExam();
  }
}
export default StartTest
