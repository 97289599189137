import React from 'react';

import { useHistory } from 'react-router-dom';
import { logout } from './apiHelper';
  const Logout = () => {
  const history = useHistory();

  const handleClearCacheAndRedirect = () => {

    logout().then(()=>{
      setTimeout(() => {
        history.push('/newLogin');
      }, 2000);
    })

    // Clear cache logic (if needed)
    // ...

    // Redirect to a specific route
    // localStorage.clear();
    // history.push('/newLogin');
  };

     // <button onClick={handleClearCacheAndRedirect}>Sign Out</button>
  return (
    <div>
      <li><a href="#"><em className="icon ni ni-signout"/><span onClick={handleClearCacheAndRedirect}>Sign Out</span></a></li>
    </div>
  );
};
  export default Logout
