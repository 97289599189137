// src/routes/DashboardRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import NewDashboard from '../Dashboard/NewDashboard';
import AdminDashboard from '../Dashboard/AdminDashboard';
import MonitorDashboard from '../Dashboard/MonitorDashboard';
import NewMonitorDashboard from '../Dashboard/NewMonitorDashboard';
import MonitorGridDashboard from '../Dashboard/MonitorGridDashboard';
import LiveDashboard from '../Dashboard/LiveDashboard';
import LiveStudentDashboard from '../Dashboard/LiveStudentDashboard';
import ExamCompleted from '../Dashboard/ExamCompleted';
import NewExamCompleted from '../Dashboard/NewExamCompleted';
import FlexiExamDashboard from '../Dashboard/FlexiExamDashboard';
import UpcomingExamDashboard from '../Dashboard/UpcomingExamDashboard';
import UnscheduledExamDashboard from '../Dashboard/UnscheduledExamDashboard';
import AdminExamDashboard from '../Dashboard/AdminExamDashboard';
import PastExamDashboard from '../Dashboard/PastExamDashboard';
import NewExamDashboard from '../Dashboard/NewExamDashboard';
import ExamCardDashboard from '../Dashboard/ExamCardDashboard';

const DashboardRoutes = () => (
    <>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/newdashboard" component={NewDashboard} />
        <Route exact path="/admindashboard" component={AdminDashboard} />
        <Route exact path="/monidash" component={MonitorDashboard} />
        <Route exact path="/newmonidash" component={NewMonitorDashboard} />
        <Route exact path="/monigriddash" component={MonitorGridDashboard} />
        <Route exact path="/livedash" component={LiveDashboard} />
        <Route exact path="/livestudentdash" component={LiveStudentDashboard} />
        <Route exact path="/completed" component={ExamCompleted} />
        <Route exact path="/newcompleted" component={NewExamCompleted} />
        <Route exact path="/testdash" component={FlexiExamDashboard} />
        <Route exact path="/upcoming" component={UpcomingExamDashboard} />
        <Route exact path="/flexi" component={FlexiExamDashboard} />
        <Route exact path="/unscheduled" component={UnscheduledExamDashboard} />
        <Route exact path="/allexamdash" component={AdminExamDashboard} />
        <Route exact path="/pastexamdash" component={PastExamDashboard} />
        <Route exact path="/newexamdash" component={NewExamDashboard} />
        <Route exact path="/examcarddash" component={ExamCardDashboard} />
    </>
);

export default DashboardRoutes;
