import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Http from 'axios'
import NewStudentCard from './NewStudentCard'
import Pubnub from '../Chat/Pubnub'

import NewLogin from '../Auth/NewLogin'


class NewMonitorDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      users: [],
      userMenu: false,
      menu: false,
      isLogged: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  async componentWillMount() {
		if(this.state.user=JSON.parse(localStorage.getItem('user')))
                   this.state.isLogged=true;
		else{
		    console.log("User not Logged in");
                    this.state.isLogged=false;
		}

    //get data from storage
    const exam = JSON.parse(localStorage.getItem('exam1'));
    this.state.exam = exam;
    const user = JSON.parse(localStorage.getItem('user'));
    this.state.user = user;
    var tout, minutes_left;

    if (this.state.exam.date !== undefined) {
      //Time passed since exam and left
      const date = Date.parse(this.state.exam.date);
      const time = Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z");
      var now = Date.now();
      var passed = (now - date - time) / 60000;


      //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
      minutes_left = Math.floor(this.state.exam.duration - passed);
      console.log(minutes_left);
      this.state.minutes_left = minutes_left;

      this.state.exam_status = (minutes_left > this.state.exam.duration) ? "Not Started" : ((minutes_left <= 0) ? "Done" : "Ongoing");
      //TODO: Minutes can be calculated from here. Put timeout or time interval?
      if (this.state.exam_status != "Done") {
        //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
        tout = setInterval(() => { this.state.exam_status = (this.state.minutes_left-- > this.state.exam.duration) ? "Not Started" : ((minutes_left < 0) ? "Done" : "Ongoing") }, 60000);
      }
      else if (this.state.exam_status == "Done" || this.state.minutes_left <= 0) {
        clearInterval(tout);
        return;
      }
    }
    else {
      console.log("Exam Unscheduled");
    }

    //ask user data
    var api;
    if (exam)
      api = 'user-api/useru/' + exam.id;
    else
      api = 'user-api/user';


    const response = await Http.get(api);
    this.setState({ isLoaded: true, users: response.data });



  }
  //get the state every 30 seconds
  async componentDidMount() {
    //regulary monitor candidate states
    this.timerID = setInterval(() => {
      this.updateState();
    }, 30000);
  }
  componentWillUnmount() {
    // In Monitor Dashboard only 1 timer is there
    clearInterval(this.timerID);
  }
  async updateState(event) {
    const errorMsgElement = document.querySelector('span#errorMsg');
    //same as componentWillMount
    /*const exam = JSON.parse(localStorage.getItem('exam1'));
    this.state.exam=exam;*/
    //Get User State Every Minute
    if (this.state.exam_status != "Done") {
      var api;
      if (this.state.exam)
        api = 'user-api/useru/' + this.state.exam.id;
      else
        api = 'user-api/user';

      const response = await Http.get(api);
      this.setState({ isLoaded: true, users: response.data });
      document.getElementById('last_updated').innerHTML = (Date());
      this.state.exam_status = ((this.state.minutes_left > this.state.exam.duration) ? "Not Started" : ((this.state.minutes_left <= 0) ? "Done" : "Ongoing"));
    }
    else if (this.state.exam_status == "Done" || this.state.minutes_left <= 0) {
      clearInterval(this.timerID);
      console.log("Exam Over. Stopped Requesting status");
      errorMsgElement.innerHTML = "Exam Over. Stopped Requesting status";
      alert("Exam Over");
    }
  }
  toggleFullScreen() {
    var elem = document.getElementById('student-grid');

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }
  }

  //take start time and add offset to it. Display localestring
  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    //x+=this.state.user.timezone*60000;
    //return(x.toLocaleString().slice(16,24));
    return (x.toLocaleTimeString());
  }
  toLocalTime = () => {
    console.log(this.props.exam.date);
    var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
    return x.toLocaleTimeString();
  }

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    this.setState({ id: 0 });
  }

  AllowUser = (id, token) => {
    alert(id + " allowed");
    //Http.defaults.headers.common['Authorization'] = token;
    Http.put('user-api/users/' + id, {
      "status": "Verified",
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        console.log("User updated Successfully");
      });
    Http.put('exam-api/examv/' + id + '/' + this.state.exam.id + '/' + "Verified",
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        console.log("User updated Successfully");
      });
  }

  DenyUser = (id) => {
    alert(id + " denied");
    Http.put('user-api/users/' + id, {
      "status": "Denied",
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        console.log("User updated Successfully");
      });
    Http.put('exam-api/examv/' + id + '/' + this.state.exam.id + '/' + "Denied",
      {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        console.log("User updated Successfully");
      });
  }


  updateRemarks = (user) => {
    const errorMsgElement = document.querySelector('span#errorMsg');
    console.log(this.state.remarks);
    var api = "exam-api/examr/" + user.id + "/" + this.state.exam.id + "/" + this.state.remarks;
    Http.put(api)
      .then(res => {
        console.log(res);
        console.log("Remarks given successfully");
        errorMsgElement.innerHTML = "Remarks given successfully";
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  toLocalLast = (last_login) => {
    if (last_login == "")
      return ("Exam Not Given");
    var offset = new Date().getTimezoneOffset();
    //var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z')-offset*60000);
    var x = new Date(Date.parse(last_login) - offset * 60000);
    return (x.toLocaleString());
  }

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  isLive = (date_from_database) => {
    var date1 = new Date();
    var date2 = new Date(date_from_database);
    var offset = new Date().getTimezoneOffset();
    console.log(date1 - date2);
    if (((date1 - date2) + offset * 60000) > 300000) {
      console.log('Delayed by more than 5 mins');
      return "Offline";
    }
    else {
      return "Online";
    }
  }

  render() {
    if(this.state.isLogged) {
    const show = this.state.menu ? "active" : "";
    const showMenu = this.state.menu ? "content-active" : "";
    const usershow = this.state.userMenu ? "show" : "";
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="images/favicon.png" />
        {/* Page Title  */}
        <title> Student Grid | MonitorExam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                      <a href="#" className="logo-link">
                        <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                      </a>
                    </div>{/* .nk-header-brand */}
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                            <div className="user-toggle">
                              <div className="user-avatar">
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
                                  <span>{this.state.user.first_name.charAt(0)}{this.state.user.last_name.charAt(0)}</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                  <span className="sub-text">{this.state.user.otp}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="#"><em className="icon ni ni-signout" /><span onClick={() => localStorage.clear()}>Sign out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className="d-lg-none">
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + (this.state.menu ? "active" : "")} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
                  </div>{/* .nk-header-wrap */}
                </div>{/* .container-fliud */}
              </div>
              {/* main header @e */}
              {/* content @s */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li className="nk-menu-heading d-none">
                            <h6 className="overline-title">Menu</h6>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                          </li>
                          <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                            <a href="\testdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item active"><a href="#" className="nk-menu-link"><span className="nk-menu-text">Registered Students</span></a></li>
                              <li className="nk-menu-item"><a href="\livedash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item"><a href="#studentsneedingattention" className="nk-menu-link"><span className="nk-menu-text">Suspicious Students</span></a></li>
                              <li className="nk-menu-item"><a href="\newcompleted" className="nk-menu-link"><span className="nk-menu-text">Completed Students</span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="nk-block-head nk-block-head-lg">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title">Student Grid</h2>
                              <div className="nk-block-des card card-bordered pr-20 pl-20 pr-20" style={{ backgroundColor: "#f3f6ff",display:"none" }}>
                                <p><strong> Students Registered for Exam <em>{this.state.exam.name}</em></strong></p>
                                <p className="exam-name mb-1">ID: EXAM00{this.state.exam.id}</p>
                                <p className="exam-name mb-1">Name: {this.state.exam.name}</p>
                                <p className="attendance-marked">Status: {this.state.exam.status}</p>
                                <p className="attendance-marked">Duration: {this.state.exam.duration} minutes</p>
                                <p className="attendance-marked">Date: {(this.state.exam.date !== undefined) ? Intl.DateTimeFormat().format(Date.parse(this.state.exam.date) + Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z")) : ""} {this.state.exam.start_time !== undefined ? this.toLocal(this.state.exam.start_time) : "Exam not Scheduled by Administrator"}</p>
                                <p className="attendance-marked" style={{ display: (this.state.exam_status == "Not Started") ? "none" : "" }} >Time Left: {this.state.exam.start_time !== undefined ? this.state.minutes_left + " minutes" : "Exam not Scheduled"}</p>
                                <p id="last_updated"></p>
                                <p><span id="timeMsg" style={{ display: 'block' }}> </span></p>
                                <p><em><span id="errorMsg" style={{ display: 'block' }}></span></em></p>
                              </div>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                      </div>
                      <div className="nk-content-body" id="studentsneedingattention">
                        <div className="nk-content-wrap">
                          <div className="nk-block">
                            <div className="row student-dashboard" id="student-grid">
                              <>
                                {this.state.users.map(user => (<NewStudentCard user={user} />)) }
                              </>
                                    <a onClick={this.toggleFullScreen} className="see-full-screen" style={{  textAlign: "center", margin: "20px" }}> See Full Screen</a>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <Pubnub users={this.state.users} />
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
        </div >
        {/*footer*/}
        {/* app-root @e */}
        {/* JavaScript */}
      </div >
    );
  }
    else {
      return(<NewLogin />);
    }
  }
}
export default NewMonitorDashboard
