import React, { Component } from "react";
import ReactDOM from "react-dom";
import Http from "axios";
import NewStudentCard from "./NewStudentCard";
import Pubnub from "../Chat/Pubnub";
import NewLogin from "../Auth/NewLogin";
import CountdownTimer from "../Util/CountdownTimer";
import {
  getFromSessionStorage,
  SESSION_STORAGE_KEYS,
} from "../Util/sessionStorage";
import { apiGet, apiPut } from "../Util/apiHelper";

class NewMonitorDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      users: [],
      user: {},
      exam: {},
      userMenu: false,
      menu: false,
      isLogged: false,
      minutes_left: 0,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  async componentWillMount() {
    if ((this.state.user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)))
      this.state.isLogged = true;
    else {
      console.log("User not Logged in");
      this.state.isLogged = false;
    }

    //get data from storage
    const exam = getFromSessionStorage(SESSION_STORAGE_KEYS.exam1);
    this.state.exam = exam;
    //const user = JSON.parse(sessionStorage.getItem('user'));
    //this.state.user = user;
    var tout, minutes_left;
    if (this.state.exam.window_start_date !== undefined) {
      this.state.exam.date = this.state.exam.window_start_date;
      this.state.exam.start_time = this.state.exam.window_start_time;
      var date = Date.parse(this.state.exam.date);
      var time = Date.parse(
        "1970-01-01T" + this.state.exam.start_time.toString() + "Z"
      );

      var now = Date.now();
      var passed = (now - date - time) / 60000;
      // if(passed > 0)
      if (
        Math.floor(passed) <
        Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration)
      )
        minutes_left =
          Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration) -
          Math.floor(passed);
      else minutes_left = 0;
      //  else
      //    minutes_left=-1; //not started
      this.state.minutes_left = minutes_left;
      this.state.exam_status =
        minutes_left >
        Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration)
          ? "Not Started"
          : minutes_left <= 0
          ? "Done"
          : "Ongoing";
      if (this.state.exam_status != "Done") {
        //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
        tout = setInterval(() => {
          this.state.exam_status =
            this.state.minutes_left-- >
            Math.floor(this.state.exam.window_duration) +
              Math.floor(this.state.exam.duration)
              ? "Not Started"
              : minutes_left < 0
              ? "Done"
              : "Ongoing";
        }, 60000);
      } else if (
        this.state.exam_status == "Done" ||
        this.state.minutes_left <= 0
      ) {
        clearInterval(tout);
        //return;
      }
    } else if (this.state.exam.date !== undefined) {
      //Time passed since exam and left
      const date = Date.parse(this.state.exam.date);
      const time = Date.parse(
        "1970-01-01T" + this.state.exam.start_time.toString() + "Z"
      );
      var now = Date.now();
      var passed = (now - date - time) / 60000;

      //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
      minutes_left = Math.floor(this.state.exam.duration - passed);
      console.log(minutes_left);
      this.state.minutes_left = minutes_left;

      this.state.exam_status =
        minutes_left > this.state.exam.duration
          ? "Not Started"
          : minutes_left <= 0
          ? "Done"
          : "Ongoing";
      //TODO: Minutes can be calculated from here. Put timeout or time interval?
      if (this.state.exam_status != "Done") {
        //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
        tout = setInterval(() => {
          this.state.exam_status =
            this.state.minutes_left-- > this.state.exam.duration
              ? "Not Started"
              : minutes_left < 0
              ? "Done"
              : "Ongoing";
        }, 60000);
      } else if (
        this.state.exam_status == "Done" ||
        this.state.minutes_left <= 0
      ) {
        clearInterval(tout);
        //return;
      }
    } else {
      console.log("Exam Unscheduled");
    }

    //ask user data
    var api;
    if (exam) api = "/user-api/useru/" + exam.id;
    else api = "/user-api/user";

    // const response = await Http.get(api);
    const response = await apiGet(api);
    console.log("resp", response);
    this.setState({ isLoaded: true, users: response });
  }
  //get the state every 30 seconds
  async componentDidMount() {
    //regulary monitor candidate states
    this.timerID = setInterval(() => {
      this.updateState();
    }, 30000);
  }
  componentWillUnmount() {
    // In Monitor Dashboard only 1 timer is there
    clearInterval(this.timerID);
  }

  getExamStatus = (exam) => {
    const examStartTime = Date.parse(
      "1970-01-01T" + exam.window_start_time.toString() + "Z"
    );
    const examEndTime = examStartTime + exam.duration * 60000;
    const now = Date.now();

    if (Date.parse(exam.window_start_date) + examStartTime < now) {
      if (examEndTime > now) {
        return "Start Exam";
      } else {
        return "Done";
      }
    } else {
      return "Not Started";
    }
  };

  async updateState(event) {
    const errorMsgElement = document.querySelector("span#errorMsg");
    //same as componentWillMount
    /*const exam = JSON.parse(sessionStorage.getItem('exam1'));
    this.state.exam=exam;*/
    //Get User State Every Minute
    //if (this.state.exam_status != "Done") {
    var api;
    if (this.state.exam) api = "/user-api/useru/" + this.state.exam.id;
    else api = "/user-api/user";

    const response = await apiGet(api);
    this.setState({ isLoaded: true, users: response });
    //document.getElementById("last_updated").innerHTML = Date();
    this.state.exam_status =
      this.state.minutes_left > this.state.exam.duration
        ? "Not Started"
        : this.state.minutes_left <= 0
        ? "Done"
        : "Ongoing";
    //}
    /* else if (this.state.exam_status == "Done" || this.state.minutes_left <= 0) {
      clearInterval(this.timerID);
      console.log("Exam Over. Stopped Requesting status");
      errorMsgElement.innerHTML = "Exam Over. Stopped Requesting status";
      alert("Exam Over");
    }*/
  }

  //take start time and add offset to it. Display localestring
  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    //x+=this.state.user.timezone*60000;
    //return(x.toLocaleString().slice(16,24));
    return x.toLocaleTimeString();
  };
  toLocalTime = () => {
    console.log(this.props.exam.date);
    var x = new Date(
      this.props.exam.date + "T" + this.props.exam.start_time + "Z"
    );
    return x.toLocaleTimeString();
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    this.setState({ id: 0 });
  };

  AllowUser = (id, token) => {
    alert(id + " allowed");
    //Http.defaults.headers.common['Authorization'] = token;
    apiPut(
      "/user-api/users/" + id,
      {
        status: "Verified",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      console.log("User updated Successfully");
    });
    apiPut(
      "/exam-api/examv/" + id + "/" + this.state.exam.id + "/" + "Verified",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      console.log("User updated Successfully");
    });
  };

  DenyUser = async (id) => {
    alert(id + " denied");

    try {
      await apiPut(
        "/user-api/users/" + id,
        {
          status: "Denied",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("User updated Successfully");
    } catch (error) {
      console.log("error updating user", error);
    }
    try {
      await apiPut(
        "/exam-api/examv/" + id + "/" + this.state.exam.id + "/" + "Denied"
      );
      console.log("User exam status updated to Denied successfully");
    } catch (error) {
      console.error("Error updating exam status:", error);
    }
  };

  updateRemarks = async (user) => {
    const errorMsgElement = document.querySelector("span#errorMsg");
    console.log(this.state.remarks);
    var api =
      "/exam-api/examr/" +
      user.id +
      "/" +
      this.state.exam.id +
      "/" +
      this.state.remarks;

try {
 const res = await apiPut(api)
 console.log('Remarks given successfully',res);
 errorMsgElement.innerHTML = "Remarks given successfully";
} catch (error) {
  console.log('error giving remarks',error);
}


  };
  toLocalLast = (last_login) => {
    if (last_login == "") return "Exam Not Given";
    var offset = new Date().getTimezoneOffset();
    //var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z')-offset*60000);
    var x = new Date(Date.parse(last_login) - offset * 60000);
    return x.toLocaleString();
  };

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  isLive = (date_from_database) => {
    var date1 = new Date();
    var date2 = new Date(date_from_database);
    var offset = new Date().getTimezoneOffset();
    console.log(date1 - date2);
    if (date1 - date2 + offset * 60000 > 60000) {
      console.log("Delayed by more than 5 mins");
      return "Offline";
    } else {
      return "Online";
    }
  };

  render() {
    if (this.state.isLogged) {
      const show = this.state.menu ? "active" : "";
      const showMenu = this.state.menu ? "content-active" : "";
      const usershow = this.state.userMenu ? "show" : "";
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title> Registered Students Dashboard | MonitorExam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link
            id="skin-default"
            rel="stylesheet"
            href="assets/css/theme.css?ver=1.2.0"
          />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img
                            className="logo-dark logo-img"
                            src="images/monitor-exam-logo.png"
                            srcSet="images/monitor-exam-logo-2x.png 2x"
                            alt="logo-dark"
                          />
                        </a>
                      </div>
                      {/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li
                            onClick={this.toggleUserMenu}
                            className={"dropdown user-dropdown " + usershow}
                          >
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <div className="user-toggle">
                                <div className="user-avatar">
                                  <img
                                    src={
                                      "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                      this.state.user.reg_pic.split("'")[1]
                                    }
                                    className="regpic "
                                    alt="reg"
                                    style={{
                                      transform: "scale(0.5)",
                                      left: "6px",
                                    }}
                                  />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">
                                  {this.state.user.first_name}{" "}
                                  {this.state.user.last_name}
                                </div>
                              </div>
                            </a>
                            <div
                              className={
                                "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " +
                                usershow
                              }
                            >
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>
                                      {this.state.user.first_name.charAt(0)}
                                      {this.state.user.last_name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">
                                      {this.state.user.first_name}{" "}
                                      {this.state.user.last_name}
                                    </span>
                                    <span className="sub-text">
                                      {this.state.user.email}
                                    </span>
                                    <span className="sub-text">
                                      {this.state.user.otp}
                                    </span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#">
                                      <em className="icon ni ni-setting" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a href="\viewprofile">
                                      <em className="icon ni ni-user-alt" />
                                      <span>View Profile</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/security">
                                      <em className="icon ni ni-setting-alt" />
                                      <span>Security Setting</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <em className="icon ni ni-activity-alt" />
                                      <span>Login Activity</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a href="\newlogin">
                                      <em className="icon ni ni-signout" />
                                      <span
                                        onClick={() => sessionStorage.clear()}
                                      >
                                        Sign out
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          {/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a
                              href="#"
                              className={
                                "toggle nk-quick-nav-icon mr-n1 " +
                                (this.state.menu ? "active" : "")
                              }
                              data-target="sideNav"
                            >
                              <em className="icon ni ni-menu" />
                            </a>
                          </li>
                        </ul>
                        {/* .nk-quick-nav */}
                      </div>
                      {/* .nk-header-tools */}
                    </div>
                    {/* .nk-header-wrap */}
                  </div>
                  {/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div
                        className={"nk-aside " + showMenu}
                        data-content="sideNav"
                        data-toggle-overlay="true"
                        data-toggle-screen="lg"
                        data-toggle-body="true"
                      >
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading d-none">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-users" />
                                </span>
                                <span className="nk-menu-text">
                                  Student Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item ">
                                  <a
                                    href="\livestudentdash"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item ">
                                  <a
                                    href="\newdashboard"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      All Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className={
                                "nk-menu-item " +
                                (this.state.user.role == "invig"
                                  ? "active"
                                  : "noactive")
                              }
                            >
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-file-docs" />
                                </span>
                                <span className="nk-menu-text">
                                  Exam Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item active">
                                  <a href="#" className="nk-menu-link">
                                    <span className="nk-menu-text">
                                      Registered Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a href="\livedash" className="nk-menu-link">
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="#studentsneedingattention"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Suspicious Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="\newcompleted"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Completed Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-signout" />
                                </span>
                                <span
                                  onClick={() => sessionStorage.clear()}
                                  className="nk-menu-text"
                                >
                                  Log Out
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav">
                            <em className="icon ni ni-cross" />
                          </a>
                        </div>
                        {/* .nk-aside-close */}
                      </div>
                      {/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title">
                                  Registered Students Dashboard
                                </h5>
                                <div className="row">
                                  <div className="col-sm-4">
                                    <p className="exam-name mb-1">
                                      ID: EXAM00{this.state.exam.id}
                                    </p>
                                    <h6 className="text-primary pl-4">
                                      {this.state.exam.name}
                                    </h6>
                                  </div>
                                  <div className="col-sm-4">
                                    {/* <p className="attendance-marked"> */}
                                    {/*   Status: {this.state.exam_status} */}
                                    {/* </p> */}
                                    <progress
                                      className="d-none"
                                      value={
                                        this.state.exam.duration -
                                        this.state.minutes_left
                                      }
                                      max={this.state.exam.duration}
                                      style={{
                                        color: "#6576ff",
                                        backgroundColor: "#f5f6fa",
                                      }}
                                      width="350px"
                                      height="25px"
                                    >
                                      {this.state.exam.duration -
                                        this.state.minutes_left}
                                    </progress>
                                    <div className="progress progress-lg d-none">
                                      <div
                                        className="progress-bar"
                                        data-progress={
                                          this.state.exam.duration -
                                          this.state.minutes_left
                                        }
                                        style={{
                                          width: `${
                                            ((this.state.exam.duration -
                                              this.state.minutes_left) /
                                              this.state.exam.duration) *
                                            100
                                          }%`,
                                        }}
                                      >
                                        {this.state.exam.duration -
                                          this.state.minutes_left}{" "}
                                        minutes
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-sm-4"> */}
                                  {/*   <div */}
                                  {/*     className="text-right" */}
                                  {/*     style={{ color: "#6576ff" }} */}
                                  {/*   > */}
                                  {/*     Time left :{" "} */}
                                  {/*     <CountdownTimer */}
                                  {/*       initialMinutes={this.state.minutes_left} */}
                                  {/*     /> */}
                                  {/*     Time left : {this.state.minutes_left} minutes<span id="timer" /> */}
                                  {/*   </div> */}
                                  {/* </div> */}
                                </div>
                                <div
                                  className="nk-block-des card card-bordered pr-20 pl-20 pr-20 d-none"
                                  style={{ backgroundColor: "#f3f6ff" }}
                                >
                                  <p>
                                    <strong>
                                      {" "}
                                      Students Registered for Exam{" "}
                                      <em>{this.state.exam.name}</em>
                                    </strong>
                                  </p>
                                  <p className="exam-name mb-1">
                                    ID: EXAM00{this.state.exam.id}
                                  </p>
                                  <p className="attendance-marked">
                                    Status: {this.state.exam.status}
                                  </p>
                                  <p className="attendance-marked">
                                    Duration: {this.state.exam.duration} minutes
                                  </p>
                                  <p
                                    className={
                                      "attendance-marked " +
                                        this.state.exam.date ===
                                      undefined
                                        ? ""
                                        : "d-none"
                                    }
                                  >
                                    Date:{" "}
                                    {this.state.exam.date !== undefined
                                      ? Intl.DateTimeFormat().format(
                                          Date.parse(this.state.exam.date) +
                                            Date.parse(
                                              "1970-01-01T" +
                                                this.state.exam.start_time.toString() +
                                                "Z"
                                            )
                                        )
                                      : ""}{" "}
                                    {this.state.exam.start_time !== undefined
                                      ? this.toLocal(this.state.exam.start_time)
                                      : "Exam not Scheduled by Administrator"}
                                  </p>
                                  <p
                                    className="attendance-marked"
                                    style={{
                                      display:
                                        this.state.exam_status == "Not Started"
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    Time Left:{" "}
                                    {this.state.exam.start_time !== undefined
                                      ? this.state.minutes_left + " minutes"
                                      : "Exam not Scheduled"}
                                  </p>
                                  <p className="attendance-marked">
                                    {" "}
                                    Time left :{" "}
                                    <CountdownTimer
                                      initialMinutes={this.state.minutes_left}
                                    />
                                  </p>
                                  <p id="last_updated d-none">Last Updated: </p>
                                  <p>
                                    <span
                                      id="timeMsg"
                                      style={{ display: "block" }}
                                    >
                                      {" "}
                                    </span>
                                  </p>
                                  <p>
                                    <em>
                                      <span
                                        id="errorMsg"
                                        style={{ display: "block" }}
                                      ></span>
                                    </em>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          <p>
                            <a href="#chat">Chat</a> with Student
                          </p>
                          <div className="nk-block row">
                            <div className="card col-sm-9">
                              <table
                                className="table table-member"
                                id="myTable"
                              >
                                <thead className="tb-member-head thead-light">
                                  <tr className="tb-member-item">
                                    <th className="tb-member-info text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Name
                                      </span>
                                      <span className="overline-title d-md-none">
                                        Details
                                      </span>
                                      <span className="overline-title d-md-none" />
                                    </th>
                                    <th className="tb-member-role tb-col-md text-center">
                                      <span className="overline-title">
                                        Status
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Last Seen
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Cred Score
                                      </span>
                                    </th>
                                    {/*<th className="tb-member-action">
                                    <span className="overline-title d-md-inline-block d-none">Remarks</span>
                                  </th> */}
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none"></span>
                                    </th>
                                    <th className="tb-tnx-action d-none text-center">
                                      <span>&nbsp;</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="tb-member-body">
                                  <>
                                    {this.state.users.map((user) => (
                                      <tr className="tb-member-item text-center">
                                        <td className="tb-member-info text-center">
                                          <div className="user-card">
                                            <div>
                                              <div className="custom-control custom-control-sm custom-checkbox d-none">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input"
                                                  id="customCheck2"
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="customCheck2"
                                                >
                                                  &nbsp;
                                                </label>
                                              </div>
                                              <div className="box ">
                                                <img
                                                  src={
                                                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                                    user.reg_pic.split("'")[1]
                                                  }
                                                  className="regpic limsthumb"
                                                  alt="reg"
                                                />
                                                <div
                                                  className="status-circle"
                                                  style={{
                                                    backgroundColor:
                                                      this.isLive(
                                                        user.last_login
                                                      ) == "Offline"
                                                        ? "gray"
                                                        : "green",
                                                  }}
                                                ></div>
                                                <a
                                                  href="#"
                                                  className=" tick btn btn-icon"
                                                  onClick={() =>
                                                    this.AllowUser(
                                                      user.id,
                                                      user.token
                                                    )
                                                  }
                                                  style={{
                                                    display:
                                                      user.status == "Verifying"
                                                        ? ""
                                                        : "none",
                                                    color: "green",
                                                    fontSize: "20px",
                                                  }}
                                                >
                                                  <em className="icon ni ni-check"></em>
                                                </a>
                                                <img
                                                  src={
                                                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                                    user.live_pic.split("'")[1]
                                                  }
                                                  style={{
                                                    display:
                                                      user.live_pic.split(
                                                        "'"
                                                      )[1] === undefined
                                                        ? "none"
                                                        : "",
                                                    top:
                                                      user.status == "Verifying"
                                                        ? ""
                                                        : "0px",
                                                  }}
                                                  className="livepic limsthumb"
                                                  alt="live"
                                                />
                                                <div
                                                  className="status-circle"
                                                  style={{
                                                    backgroundColor:
                                                      this.isLive(
                                                        user.last_login
                                                      ) == "Offline"
                                                        ? "gray"
                                                        : "green",
                                                  }}
                                                ></div>
                                                <a
                                                  href="#"
                                                  className=" cross btn btn-icon"
                                                  onClick={() =>
                                                    this.DenyUser(user.id)
                                                  }
                                                  style={{
                                                    display:
                                                      user.status == "Verifying"
                                                        ? ""
                                                        : "none",
                                                    color: "red",
                                                    fontSize: "20px",
                                                  }}
                                                >
                                                  <em className="icon ni ni-cross"></em>
                                                </a>
                                              </div>
                                              <div
                                                className="dash-image"
                                                style={{ display: "none" }}
                                              >
                                                <img
                                                  src={
                                                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                                    user.reg_pic.split("'")[1]
                                                  }
                                                  className="limsthumb"
                                                  alt="reg"
                                                />
                                                <img
                                                  src={
                                                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                                    user.live_pic.split("'")[1]
                                                  }
                                                  className="limsthumb"
                                                  alt="live"
                                                  style={{
                                                    display:
                                                      typeof user.live_pic !==
                                                      "undefined"
                                                        ? ""
                                                        : "none",
                                                  }}
                                                />
                                                <div className="approve-disapprove-images">
                                                  <p
                                                    className="btn btn-icon"
                                                    onClick={() =>
                                                      this.AllowUser(
                                                        user.id,
                                                        user.token
                                                      )
                                                    }
                                                    style={{
                                                      display:
                                                        user.status ==
                                                        "Verifying"
                                                          ? "flex"
                                                          : "none",
                                                      color: "green",
                                                      position: "relative",
                                                      left: "-100px",
                                                      top: "50px",
                                                      fontSize: "24px",
                                                    }}
                                                  >
                                                    <em className="icon ni ni-check-round"></em>
                                                  </p>
                                                  <p
                                                    className="btn btn-icon"
                                                    onClick={() =>
                                                      this.DenyUser(user.id)
                                                    }
                                                    style={{
                                                      display:
                                                        user.status ==
                                                        "Verifying"
                                                          ? "flex"
                                                          : "none",
                                                      position: "relative",
                                                      color: "red",
                                                      fontSize: "24px",
                                                    }}
                                                  >
                                                    <em className="icon ni ni-cross-round"></em>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="user-info"
                                              style={{ paddingLeft: "30px" }}
                                            >
                                              <a href={"#student" + user.id}>
                                                <span className="lead-text">
                                                  {user.first_name}{" "}
                                                  {user.last_name}
                                                </span>
                                              </a>
                                              <span className="sub-text">
                                                STUD00{user.id}
                                              </span>
                                              <span className="sub-text">
                                                {user.email}
                                              </span>
                                              <span className="sub-text">
                                                {user.otp}
                                              </span>
                                              <span className="sub-text contact d-md-none">
                                                {user.status}
                                              </span>
                                              <span className="sub-text date d-md-none">
                                                {this.toLocalLast(
                                                  user.last_login
                                                )}
                                              </span>
                                              <span className="sub-text contact d-md-none">
                                                {user.cred}
                                              </span>

                                              <span className="overline-title d-md-inline-block d-none">
                                                <input
                                                  type="text"
                                                  name="remarks"
                                                  className="sub-text d-md-none contact"
                                                  id="customCheck2"
                                                  placeholder="Remarks"
                                                  onChange={this.handleChange}
                                                />
                                              </span>
                                              <span className="sub-text contact d-md-none">
                                                <a
                                                  href="#errorMsg"
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    this.updateRemarks(user)
                                                  }
                                                >
                                                  Send
                                                </a>
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="tb-member-status tb-col-md d-none text-center">
                                          <span>{user.status}</span>
                                        </td>
                                        <td className="tb-member-action tb-col-md d-none text-center">
                                          <span>
                                            {this.toLocalLast(user.last_login)}
                                          </span>
                                        </td>
                                        <td className="tb-member-cred tb-col-md d-none text-center">
                                          <span>{user.cred}</span>
                                        </td>
                                        <td
                                          className="tb-member-remarks d-none text-center"
                                          style={{
                                            display:
                                              this.state.user.role == "invig"
                                                ? ""
                                                : "none",
                                          }}
                                        >
                                          <input
                                            name="remarks"
                                            type="text"
                                            placeholder="Remarks"
                                            className="form-control form-control-lg"
                                            onChange={this.handleChange}
                                          />
                                          <a
                                            href="#"
                                            onClick={() =>
                                              this.updateRemarks(user)
                                            }
                                            className="btn btn-icon-round btn-outline-primary"
                                          >
                                            <em className="ni ni-icon ni-send"></em>
                                          </a>
                                        </td>
                                        <td className="tb-tnx-action d-none text-center">
                                          <div className="dropdown">
                                            <a
                                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                              data-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <em className="icon ni ni-more-h" />
                                            </a>
                                            <div
                                              className="dropdown-menu dropdown-menu-right dropdown-menu-xs"
                                              style={{}}
                                            >
                                              <ul className="link-list-plain">
                                                <li>
                                                  <a href="#">View</a>
                                                </li>
                                                <li>
                                                  <a href="#">Edit</a>
                                                </li>
                                                <li>
                                                  <a href="#">Remove</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-sm-3">
                              <Pubnub users={this.state.users} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="nk-content-body"
                          id="studentsneedingattention"
                        >
                          <div className="nk-content-wrap">
                            <div className="nk-block-head nk-block-head-lg">
                              <div className="nk-block-between-md g-4">
                                <div className="nk-block-head-content">
                                  <h5 className="nk-block-title">
                                    Students Needing Attention
                                  </h5>
                                  <div className="nk-block-des">
                                    <p>
                                      <a href="#chat"> Chat with Students </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* .nk-block-head */}
                            <div className="nk-block">
                              <div className="row student-dashboard">
                                <>
                                  {this.state.users
                                    .filter(
                                      (user) =>
                                        user.status.includes("Away") ||
                                        user.status.includes("Looking") ||
                                        user.status.includes("Distracted") ||
                                        user.status.includes("Doubt")
                                    )
                                    .map((user) => (
                                      <NewStudentCard user={user} />
                                    ))}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* footer @e */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* content @e */}
              </div>
              {/* wrap @e */}
            </div>
            {/* main @e */}
          </div>
          {/*footer*/}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    } else {
      return <NewLogin />;
    }
  }
}
export default NewMonitorDashboard;
