import React, {
	Component,
	useState,
	useEffect
} from 'react'
import {
	Redirect
} from 'react-router-dom'
import NewLogin from '../Auth/NewLogin'
import { getFromSessionStorage, SESSION_STORAGE_KEYS } from '../Util/sessionStorage';
import Header from '../components/header';
class VerifyHelp extends React.Component {
	constructor() {
		super();
		this.state = {
			userMenu: false,
			menu: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
	}
	toggleUserMenu() {
		this.setState({ userMenu: !this.state.userMenu });
	}
	toggleMenu() {
		//	this.setState({ menu: !this.state.menu });
	}
	componentDidMount() {
		const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user)
		// const user = JSON.parse(localStorage.getItem('user'));
		this.setState({
			...this.state,
			user: user,
			isLogged: true
		})
		// 	if (user) {
		// 		this.state.user = user;
		//   this.state.isLogged = true;
		// }
	}
	render() {
		if (this.state.isLogged) {
			const show = this.state.menu ? "active" : "noactive";
			const showMenu = this.state.menu ? "content-active" : "noactive";
			const usershow = this.state.userMenu ? "show" : "";
			return (
				<div>
						<Header 
						 user={this.state.user}
						/>
								{/* content @s */}
								<div className="nk-content mt-5">
									<div className="container wide-xl">
										<div className="nk-content-inner">
											<div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
												<div className="nk-sidebar-menu" data-simplebar>
													{/* Menu */}
													<ul className="nk-menu">
														<li className="nk-menu-heading d-none">
															<h6 className="overline-title">Menu</h6>
														</li>
														<li className="nk-menu-item active">
															<a href="/testdash" className="nk-menu-link">
																<span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
																<span className="nk-menu-text">Exam Dashboard</span>
															</a>
															<ul className="nk-menu-sub">
																<li className="nk-menu-item"><a href="/testdash" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
																<li className="nk-menu-item"><a href="/upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
																<li className="nk-menu-item"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
																<li className="nk-menu-item"><a href="/unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
																<li className="nk-menu-item"><a href="/pastexamdash" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
															</ul>
														</li>
														<li className="nk-menu-item">
															<a href="\newlogin" className="nk-menu-link">
																<span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
																<span className="nk-menu-text" onClick={() => localStorage.clear()}>Sign Out</span>
															</a>
														</li>
													</ul>
												</div>{/* .nk-sidebar-menu */}
												<div className="nk-aside-close">
													<a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
												</div>{/* .nk-aside-close */}
											</div>{/* .nk-aside */}
											<div className="nk-content-body">
												<div className="nk-content-wrap">
													<div className="nk-block-head nk-block-head-lg">
														<div className="nk-block-between-md g-4">
															<div className="nk-block-head-content">
																<h2 className="nk-block-title pt-70">Verification Guidelines</h2>
																<div className="nk-block-des">
																	<p>Complete your verification in 3 simple steps here</p>
																	<div className="row">
																		<div className="col-sm-12 text-left">
																			<a href="/newprofile" className="btn btn-primary mb-3 mt-3 nextpage">Start Verification</a>
																		</div>
																	</div>
																</div>
															</div>
															<div className="nk-block-head-content">
																<ul className="nk-block-tools gx-3">
																	<li />
																</ul>
															</div>
														</div>
													</div>{/* .nk-block-head */}
													<div className="nk-block">
														<div className="card card-bordered verification-box room">
															<h4 className="text-secondary text-center d-none">Verification Guidelines</h4>
															<div className="row step-card">
															</div>
															<div className="row">
																<div className="col-sm-3 step-1">
																	<div className="card card-bordered p-2 mb-2">
																		<span className="text-center mb-2">Step 1: Click Picture</span>
																		<a href="#" className="btn btn-secondary btn-block mb-2">Capture</a>
																		<img src="/images/avatar/b-sm.jpg" className="mb-2" />
																		<span className="badge badge-simple text-center">Dummy Photo Valid</span>
																	</div>
																</div>
																<div className="col-sm-3 step-2">
																	<div className="card card-bordered p-2 mb-2">
																		<span className="text-center mb-2">Step 2: Verify Enviroment</span>
																		<a href="#" className="btn btn-secondary btn-block mb-2">Update State</a>
																		<img src="/images/map.jpg" className="mb-2" />
																		<p><em className="icon ni ni-camera text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> </p>
																		<p><em className="icon ni ni-spark text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">Level 74% Not Charging</span></p>
																		<p><em className="icon ni ni-speed text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">Network speed is 3.55 Mbps</span></p>
																		<p><em className="icon ni ni-location text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">182.76.28.67</span></p>
																	</div>
																</div>
																<div className="col-sm-3 step-3">
																	<div className="card card-bordered p-2">
																		<div className="row">
																			<div className="col-sm-12 text-right" style={{ color: "white" }}>
																				<a id="wait" className="btn btn-secondary nextpage" style={{ color: "white" }}>
																					Dummy Enter Exam
																				</a>
																			</div>
																		</div>
																	</div>
																	<div className="card card-bordered p-2 noactive">
																		<span className="text-center mb-2 ">Step 3: Upload ID Card</span>
																		<a href="#" className="btn btn-secondary btn-block mb-2">Upload ID Card</a>
																		<img src="/images/avatar/b-sm.jpg" className="mb-2" />
																		<span className="badge badge-simple text-center">Dummy Photo Valid</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* footer @e */}
											</div>
										</div>
									</div>
								</div>
								{/* content @e */}
					{/*footer*/}
					{/* app-root @e */}
					{/* JavaScript */}
				</div>
			);
		}
		else {
			return null
		}

	}
}
export default VerifyHelp
