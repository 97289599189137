// src/routes/AuthRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import AuthFido from '../Auth/AuthFido';
import NewInvigAuth from '../Auth/NewInvigAuth';
import ParentAuth from '../Auth/ParentAuth';
import WebAuthn from '../Auth/WebAuthn';
import WebSecurity from '../Auth/WebSecurity';
import WebAuthnLogin from '../Auth/WebAuthnLogin';
import InvigAuth from '../Auth/InvigAuth';
import VerifyStudent from '../Auth/Verify';
import ResetPassword from '../Auth/ResetPassword';
import NewResetPassword from '../Auth/NewResetPassword';
import Profile from '../Auth/Profile';
import NewProfile from '../Auth/NewProfile';
import VerifyHelp from '../Auth/VerifyHelp';
import ViewProfile from '../Auth/ViewProfile';
import Wait from '../Auth/Wait';
import NewWait from '../Auth/NewWait';
import StartTest from '../Auth/startTest';
import Interview from '../Auth/Interview';
import NewTest from '../Auth/NewTest';
import Confirm from '../Auth/Confirm';
import NewLogin from '../Auth/NewLogin';

const AuthRoutes = () => (
    <>
        <Route exact path="/newLogin" component={NewLogin} />
        <Route exact path="/login" component={NewLogin} />
        <Route exact path="/register" component={AuthFido} />
        <Route exact path="/aisct" component={AuthFido} />
        <Route exact path="/iregister" component={InvigAuth} />
        <Route exact path="/newiregister" component={NewInvigAuth} />
        <Route exact path="/pregister" component={ParentAuth} />
        <Route exact path="/wregister" component={WebAuthn} />
        <Route exact path="/security" component={WebSecurity} />
        <Route exact path="/wlogin" component={WebAuthnLogin} />
        <Route exact path="/fregister" component={AuthFido} />
        <Route exact path="/verify" component={VerifyStudent} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/newreset" component={NewResetPassword} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/newprofile" component={NewProfile} />
        <Route exact path="/verifyhelp" component={VerifyHelp} />
        <Route exact path="/viewprofile" component={ViewProfile} />
        <Route exact path="/wait" component={Wait} />
        <Route exact path="/newwait" component={NewWait} />
        <Route exact path="/startTest/:testId" component={StartTest} />
        <Route exact path="/interview" component={Interview} />
        <Route exact path="/newtest" component={NewTest} />
        <Route exact path="/confirm" component={Confirm} />
    </>
);

export default AuthRoutes;
