
import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
    class Pricing extends React.Component {
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false
            };
	}
	
    render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="images/favicon.png" />
        {/* Page Title  */}
        <title>Pricing | Monitor Exam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="assets/css/style.css?ver=1.2.0" />
        <link rel="stylesheet" href="assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/*Header*/}
              <header className="header nk-header nk-header-fixed is-light">
                <div className="">
                  <a href className="logo"><img src="images/monitor-exam-logo.png" alt="logo" width={120} /></a>
                  <input className="menu-btn" type="checkbox" id="menu-btn" />
                  <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
                  <ul className="menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/howitworks">How It Works</a></li>
                    <li><a href="/pricing">Pricing</a></li>
                    <li><a href="/faq">FAQs</a></li>
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="auths/auth-register.html" className="btn btn-lg btn-primary header-btn">Register</a></li>
                  </ul>
                </div>
              </header>
              {/* content @s */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    {/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="nk-block-head nk-block-head-lg">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title">Choose Suitable Plan</h2>
                              <div className="nk-block-des">
                                <p>You can change your plan any time by upgrade your plan</p>
                              </div>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="nk-block mb-4">
                          <div className="row g-gs">
                            <div className="col-md-4">
                              <div className="price-plan card card-bordered text-center">
                                <div className="card-inner">
                                  <div className="price-plan-media">
                                    <img src="images/icons/plan-s1.svg" alt="" />
                                  </div>
                                  <div className="price-plan-info">
                                    <h5 className="title">Auto+</h5>
                                    <span>Completely Auto-Proctored exam with no human intervention</span>
                                  </div>
                                  <div className="price-plan-amount">
                                    <div className="amount"><sup>$</sup>4<span>/exam</span></div>
                                  </div>
                                  <div className="price-plan-action">
                                    <a href="/newiregister" className="btn btn-primary">Start Now</a>
                                  </div>
                                </div>
                              </div>{/* .price-item */}
                            </div>{/* .col */}
                            <div className="col-md-4">
                              <div className="price-item card card-bordered text-center">
                                <div className="card-inner">
                                  <div className="price-plan-media">
                                    <img src="images/icons/plan-s2.svg" alt="" />
                                  </div>
                                  <div className="price-plan-info">
                                    <h5 className="title">Verify+</h5>
                                    <span>Verification is done by live proctor but rest of exam is auto-proctored</span>
                                  </div>
                                  <div className="price-plan-amount">
                                    <div className="amount"><sup>$</sup>5<span>/exam</span></div>
                                  </div>
                                  <div className="price-plan-action">
		<a  className={"btn btn-primary"} style={{color:"white"}} href='https://www.payumoney.com/paybypayumoney/#/C3665E868F59148D931C3E3725D84FC0'>Pay Now</a>
                                  </div>
                                </div>
                              </div>{/* .price-item */}
                            </div>{/* .col */}
                            <div className="col-md-4">
                              <div className="price-item card card-bordered text-center">
                                <div className="card-inner">
                                  <div className="price-plan-media">
                                    <img src="images/icons/plan-s3.svg" alt="" />
                                  </div>
                                  <div className="price-plan-info">
                                    <h5 className="title">Live+</h5>
                                    <span>Both Verification and Live Proctoring is done by a live proctor</span>
                                  </div>
                                  <div className="price-plan-amount">
                                    <div className="amount"><sup>$</sup>15<span>/exam</span></div>
                                  </div>
                                  <div className="price-plan-action">
                                    <a href="/contact" className="btn btn-primary">Contact Us</a>
                                  </div>
                                </div>
                              </div>{/* .price-item */}
                            </div>{/* .col */}
                          </div>{/* .row */}
                        </div>{/* .nk-block */}
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <table className="table table-features">
                              <thead className="tb-ftr-head thead-light ">
                                <tr className="tb-ftr-item">
                                  <th className="tb-ftr-info">Features</th>
                                  <th className="tb-ftr-plan">Auto+</th>
                                  <th className="tb-ftr-plan">Verify+</th>
                                  <th className="tb-ftr-plan">Live+</th>
                                </tr>{/* .tb-ftr-item */}
                              </thead>
                              <tbody className="tb-ftr-body">
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Biometric</td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross" /> <span className="plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */} 
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Verification</td>
                                  <td className="tb-ftr-plan">Auto<span className="plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan">Manual <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan">Manual<span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */}
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Auto Proctoring</td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross" /> <span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */}
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Live Monitoring</td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross"/> <span class=" plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross" /> <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */}
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Customization</td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross" /> <span className="plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-cross" /> <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-check-thick" /> <span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */}                                                   
                                <tr className="tb-ftr-item">
                                  <td className="tb-ftr-info">Technical Support</td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-mail" /> <span className="plan-name">Auto+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-mail" /> <em className="icon ni ni-call" /> <span className="plan-name">Verify+</span></td>
                                  <td className="tb-ftr-plan"><em className="icon ni ni-mail" /> <em className="icon ni ni-call" /> <span className="plan-name">Live+</span></td>
                                </tr>{/* .tb-ftr-item */}
                              </tbody>
                            </table>
                          </div>
                        </div>{/* .nk-block */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
            {/*footer*/}
            {/* main @e */}
          </div>
          {/* app-root @e */}
          {/* JavaScript */}
        </div></div>
    );
  }
    }
export default Pricing
