// src/routes/FormRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Form from '../Form/Form';
import NewForm from '../Form/NewForm';
import ViewExam from '../Form/ViewExam';
import MCQTest from '../Form/MCQTest';
import NewInvite from '../Form/NewInvite';
import NewCreate from '../Form/NewCreate';  

const FormRoutes = () => (
    <>
        <Route exact path="/form" component={Form} />
        <Route exact path="/newform" component={NewForm} />
        <Route exact path="/viewexam" component={ViewExam} />
        <Route exact path="/mcqtest" component={MCQTest} />
        <Route exact path="/newinvite" component={NewInvite} />
        <Route exact path="/newcreate" component={NewCreate} />
    </>
);

export default FormRoutes;
