import React from 'react';

const InputField = ({
  type,
  name,
  id,
  placeholder,
  onChange,
  value,
  icon,
  showPassword,
  togglePassword,
  fieldClassName="form-control form-control-lg",
  isPasswordField=false
}) => (
  <div className="form-control-wrap">
    <span className="form-icon">
      <em className={`icon ni ni-${icon}`} />
    </span>
    {isPasswordField && (
      <a
        href="#"
        onClick={togglePassword}
        className="form-icon form-icon-right passcode-switch"
        data-target={name}
      >
        <em className={`passcode-icon icon-show icon ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
      </a>
    )}
    <input
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={fieldClassName}
      required
    />
  </div>
);

export default InputField;
