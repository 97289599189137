import React, { Component } from "react";
import ReactDOM from "react-dom";
import Http from "axios";
import NewLogin from "../Auth/NewLogin";
import ViewProfile from "../Auth/ViewProfile";
import { Link } from "react-router-dom";
import NewStudentCard from "./NewStudentCard";
import {
  getFromSessionStorage,
  SESSION_STORAGE_KEYS,
  clearSessionStorage,
} from "../Util/sessionStorage";
import { apiGet } from "../Util/apiHelper";
import livest from "./liveStudenst.json";


class LiveStudentDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLogged: false,
      isLoaded: false,
      users: [],
      user: {},
      userMenu: false,
      menu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }
  isLive = (date_from_database) => {
    date_from_database = date_from_database.replace(/\s{2,}/g, " ");
    var x = new Date(
      Date.parse(date_from_database.slice(0, 19).replace(" ", "T") + "Z")
    );
    var date = new Date();
    //data from database
    //console.log("current date: " + date)

    var ONE_MIN = 60 * 1000;
    if (date - x > ONE_MIN) {
      console.log('date in offline',date - x, ONE_MIN)
      console.log("Delayed by more than 1 min");
      return "Offline";
    } else {
      console.log('date in online',date - x, ONE_MIN )
      return "Online";
    }
  };

  componentDidMount() {
    const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user);
    if (user) {
      console.log("res", user);
      this.setState(
        {
          isLogged: true,
          user: user,
        },
        async () => {
          console.log("res", user);
          const api = `/user-api/userd/${user?.id}`;
          const response = await apiGet(api);
          this.setState({ isLoaded: true, users: response });
        }
      );
    }
  }
  // async componentWillMount() {

  //   const user = getFromSessionStorage(SESSION_STORAGE_KEYS.user);

  //   if (user) {
  //     console.log("res", user);
  //     // Set the state if user data exists
  //     this.setState(
  //       {
  //         isLogged: true,
  //         user: user,
  //       })
  //     }
  // }
  // async componentDidMount() {
  //         var api;
  //               Http.defaults.headers.common['Authorization'] = this.state.user.token;
  // 	if(this.state.isLogged)
  // 	{
  // 	    api='user-api/userd/'+this.state.user.id;
  // 	    const response = await Http.get(api);
  //                   this.setState({ isLoaded:true, users:response.data });
  // 	}
  // }
  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toLocalLast = (last_login) => {
    if (last_login == "") return "Exam Not Given";
    var x = new Date(
      Date.parse(last_login.slice(0, 19).replace(" ", "T") + "Z")
    );
    return x.toLocaleString();
  };
  viewProfile = (user) => {
    return <ViewProfile user={user} />;
  };
  handleLogout = () => {
    this.setState({ isLogged: false });
    localStorage.clear();
    clearSessionStorage();
    this.props.history.replace({
      pathname: "/newlogin",
      state: {
        from: "",
      },
    });
  };
  render() {
    if (this.state.isLogged) {
      const show = this.state.menu ? "active" : "";
      const showMenu = this.state.menu ? "content-active" : "";
      const usershow = this.state.userMenu ? "show" : "";
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Innokreat" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Live Students | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link
            id="skin-default"
            rel="stylesheet"
            href="assets/css/theme.css?ver=1.2.0"
          />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img
                            className="logo-dark logo-img"
                            src="images/monitor-exam-logo.png"
                            srcSet="images/monitor-exam-logo-2x.png 2x"
                            alt="logo-dark"
                          />
                        </a>
                      </div>
                      {/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li
                            onClick={this.toggleUserMenu}
                            className={"dropdown user-dropdown " + usershow}
                          >
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded={this.state.userMenu}
                            >
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                  <img
                                    src={
                                      "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                      this.state.user.reg_pic.split("'")[1]
                                    }
                                    class="regpic "
                                    alt="reg"
                                    style={{
                                      transform: "scale(0.5)",
                                      left: "6px",
                                    }}
                                  />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">
                                  {this.state.user.first_name}{" "}
                                  {this.state.user.last_name}
                                </div>
                              </div>
                            </a>
                            <div
                              className={
                                "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " +
                                usershow
                              }
                            >
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>
                                      {this.state.user.first_name.charAt(0)}
                                      {this.state.user.last_name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">
                                      {this.state.user.first_name}{" "}
                                      {this.state.user.last_name}
                                    </span>
                                    <span className="sub-text">
                                      {this.state.user.email}
                                    </span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#">
                                      <em className="icon ni ni-setting" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a href="/viewprofile">
                                      <em className="icon ni ni-user-alt" />
                                      <span>View Profile</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/security">
                                      <em className="icon ni ni-setting-alt" />
                                      <span>Security Setting</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <em className="icon ni ni-activity-alt" />
                                      <span>Login Activity</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li className="nk-menu-item">
                                    <a
                                      href="/newlogin"
                                      className="nk-menu-link"
                                    >
                                      <span className="nk-menu-icon">
                                        <em className="icon ni ni-signout" />
                                      </span>
                                      <span
                                        onClick={() => sessionStorage.clear()}
                                        className="nk-menu-text"
                                      >
                                        Log Out
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          {/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a
                              href="#"
                              className={
                                "toggle nk-quick-nav-icon mr-n1 " + show
                              }
                              data-target="sideNav"
                            >
                              <em className="icon ni ni-menu" />
                            </a>
                          </li>
                        </ul>
                        {/* .nk-quick-nav */}
                      </div>
                      {/* .nk-header-tools */}
                    </div>
                    {/* .nk-header-wrap */}
                  </div>
                  {/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div
                        className={"nk-aside " + showMenu}
                        data-content="sideNav"
                        data-toggle-overlay="true"
                        data-toggle-screen="lg"
                        data-toggle-body="true"
                      >
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className="nk-menu-item ">
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-users" />
                                </span>
                                <span className="nk-menu-text">
                                  Student Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item active">
                                  <a
                                    href="\livestudentdash"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item ">
                                  <a
                                    href="\newdashboard"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      All Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-file-docs" />
                                </span>
                                <span className="nk-menu-text">
                                  Exam Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item">
                                  <a href="\flexi" className="nk-menu-link">
                                    <span className="nk-menu-text">
                                      Flexi Exams
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="\unscheduled"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Anytime Exams
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="/newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-signout" />
                                </span>
                                <span
                                  onClick={() => sessionStorage.clear()}
                                  className="nk-menu-text"
                                >
                                  Log Out
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav">
                            <em className="icon ni ni-cross" />
                          </a>
                        </div>
                        {/* .nk-aside-close */}
                      </div>
                      {/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h2 className="nk-block-title">
                                  Live Students
                                </h2>
                              </div>
                              <div className="nk-block-head-content">
                                <ul className="nk-block-tools gx-3">
                                  <li>
                                    <a
                                      href="/newinvite"
                                      className="btn btn-primary"
                                    >
                                      +Student
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          <div className="nk-block">
                            <div className="row student-dashboard">
                              <>

                                {this.state.users
                                  .filter(
                                    (user) =>
                                      this.isLive(user.last_login) === "Online"
                                  )
                                  .map((user) => (
                                    <NewStudentCard user={user} />
                                  ))}
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    } else {
      return <NewLogin />;
    }
  }
}
export default LiveStudentDashboard;
