import React from 'react';

const CustomButton = ({ onClick, type="submit", className='btn-lg btn-primary btn-block', children, ...props }) => (
  <button
    onClick={onClick}
    type={type}
    className={`btn ${className}`}
    {...props}
  >
    {children}
  </button>
);

export default CustomButton;