// src/routes/PublicRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../Home/WFHome';
import HiW from '../Home/HiW';
import AboutUs from '../Home/WFAbout';
import Product from '../Home/Product';
import Contact from '../Home/WFContact';
import FAQ from '../Home/FAQ';
import Support from '../Home/Support';
import Pricing from '../Home/WFPricing';
import Job from '../Home/Job';
import HowItWorks from '../Home/HowItWorks';
import SubmitJotForm from '../Home/SubmitJotForm';
import Cancel from '../Home/Cancel';
import Failure from '../Home/Failure';
import Success from '../Home/Success';
import AISCT from '../Home/AISCT';
import Footer from '../Home/Footer';
import Menu from '../Menu/Menu';
import SpeechRecognitionComponent from '../Util/SpeechRecognitionComponent';

const PublicRoutes = () => (
    <>
        <Route exact path="/" component={Home} />
        <Route exact path="/hiw" component={HiW} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/job" component={Job} />
        <Route exact path="/howitworks" component={HowItWorks} />
        <Route exact path="/submitjotform/:examId/:studentId" component={SubmitJotForm} />
        <Route exact path="/cancelled" component={() => (<><Menu /><Cancel /><Footer /></>)} />
        <Route exact path="/failure" component={() => (<><Menu /><Failure /><Footer /></>)} />
        <Route exact path="/success" component={() => (<><Menu /><Success /><Footer /></>)} />
        <Route exact path="/listen" component={SpeechRecognitionComponent} />
    </>
);

export default PublicRoutes;
