// CountdownTimer.js
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialMinutes=0 }) => {
  const [timeLeft, setTimeLeft] = useState(initialMinutes * 60);

  useEffect(() => {
    if (!timeLeft) return; // Exit early when we reach 0

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [timeLeft]);

  // Convert total seconds to hours, minutes, and seconds
  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  

  return (
    <div>
      <p>
        {hours}h {minutes}m {seconds}s
      </p>
    </div>
  );
};

export default CountdownTimer;

