import React from "react";
import {BrowserRouter as Router, Route,Switch } from 'react-router-dom';
import PublicRoutes from "./publicRoutes";
import AuthRoutes from "./authRoutes";
import DashboardRoutes from "./dashboardRoutes";
import FormRoutes from "./formsRoutes";
import Footer from "../Home/Footer";

const Routes = () => (
    <Router>
        <AuthRoutes />
        <PublicRoutes />
        <DashboardRoutes />
        <FormRoutes />
        <Footer />
    </Router>
);


export default Routes