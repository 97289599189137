import Http from 'axios'
import { ALERT_MSG_ATTEMP_TO_ENABLE_FULL_SCREEN_MODE, ALERT_MSG_NOT_FULL_SCREEN } from './alertMessages';
import Base64js from 'base64-js'

export const getAssertion = (user = {}) => {

    if (window.innerHeight === window.screen.height && window.innerWidth === window.screen.width) {
        console.log('Browser is in fullscreen mode');
    } else {
        alert(ALERT_MSG_NOT_FULL_SCREEN);
    }

    if (!user?.fido_auth_enabled) {
        return;
    }

    return new Promise((resolve, reject) => {
        Http.get('/user/' + user?.email + '/exists', {}, null, 'json').then(response => {
            console.log(response);
            Http.get('/assertion/' + user.email + '?userVer=required&txAuthExtension=', {
                userVer: 'required',
                txAuthExtension: ''
            }, null, 'json')
                .then(makeAssertionOptions => {
                    console.log("Assertion Options:");
                    console.log(makeAssertionOptions);
                    makeAssertionOptions.data.publicKey.challenge = bufferDecode(makeAssertionOptions.data.publicKey.challenge);
                    makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem => {
                        listItem.id = bufferDecode(listItem.id)
                    });
                    navigator.credentials.get({
                        publicKey: makeAssertionOptions.data.publicKey
                    })
                        .then(credential => {
                            resolve(credential)
                        }).catch(err => {
                            reject(err)
                            console.log(err.name);
                            alert("Verification failed");
                        });
                });
        }).catch(error => {
            if (!error.exists) {
                alert("User not found, try registering one first!");
            }
            return;
        })
    })
}


export const verifyAssertion = (assertedCredential) => {
    // Move data into Arrays incase it is super long

    return new Promise((resolve, reject) => {
        console.log('calling verify')
        const authData = new Uint8Array(assertedCredential.response.authenticatorData);
        const clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
        const rawId = new Uint8Array(assertedCredential.rawId);
        const sig = new Uint8Array(assertedCredential.response.signature);
        const userHandle = new Uint8Array(assertedCredential.response.userHandle);

        const payload = {
            id: assertedCredential.id,
            rawId: bufferEncode(rawId),
            type: assertedCredential.type,
            response: {
                authenticatorData: bufferEncode(authData),
                clientDataJSON: bufferEncode(clientDataJSON),
                signature: bufferEncode(sig),
                userHandle: bufferEncode(userHandle),
            },
        }

        Http.post('/assertion', payload)
            .then(response => resolve(response))
            .catch(error => reject(error));

    })


}

export const bufferEncode = (value) => {
    // return Buffer.from(value).toString('base64');
    return Base64js.fromByteArray(value)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

export const bufferDecode = (value) => {
    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
}


 export const  toggleFullScreen=(selectorName='')=> {
    var elem = document.querySelector(selectorName);

    if (!document.fullscreenElement) {
      elem.requestFullscreen()
      .catch(err => {
        const msg = ALERT_MSG_ATTEMP_TO_ENABLE_FULL_SCREEN_MODE(err)
        alert(msg);
      });
    } else {
      document.exitFullscreen();
    }
  }
  
 export const toggleFullScreenOffline=(elementId='')=> {
    var elem = document.getElementById(elementId);

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        const msg = ALERT_MSG_ATTEMP_TO_ENABLE_FULL_SCREEN_MODE(err)
        alert(msg);
      });
    } else {
      document.exitFullscreen();
    }
  }


  export const isSubmitted = (exam={}) =>{
    return new Promise((resolve,reject)=>{
    /** changes for exam submission get start ss1*/
    if(exam) {        
        const api = 'user-api/userua/' + exam?.id;
        Http.get(api).then((response)=> resolve(response))
        .catch((err)=>reject(err))
    }
    })
  }


  export const isMirroringOn = async(presUrls) =>{
    return new Promise((resolve,reject)=>{
        //TODO: These are not valid URLs
        //const mMsgElement = document.querySelector('span#mMsg');
      
        const request = new PresentationRequest(presUrls);
        // this.state.mirroring_state = request.getAvailability().then(function (availability) {
         request.getAvailability().then( (availability) =>{
          // availability.value may be kept up-to-date by the controlling UA as long
          // as the availability object is alive. It is advised for the web developers
          // to discard the object as soon as it's not needed.
          console.log(availability.value);
          resolve(availability)
        //   mstatus.value = availability.value;
        //   availability.onChange = this.handleAvailabilityChange;
        //   return availability.value;
        }).catch( (err)=> {
          // Availability monitoring is not supported by the platform, so discovery of
          // presentation displays will happen only after request.start() is called.
          // Pretend the devices are available for simplicity; or, one could implement
          // a third state for the button.
          //this.handleAvailabilityChange(true);
          console.log("getAvailability returned error");
          reject(err)
        });
    })
  }