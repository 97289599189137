import React, { Component } from "react";
import ReactDOM from "react-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Http from "axios";
import StudentCard from "./StudentCard";
import NewStudentCard from "./NewStudentCard";
import Card from "react-bootstrap/Card";
import Pubnub from "../Chat/Pubnub";
import NewLogin from "../Auth/NewLogin";
import CountdownTimer from "../Util/CountdownTimer";
import MinInvigChat from "../Chat/MinInvigChat";
import Logout from "../Util/Logout";

import { useHistory } from "react-router-dom";

class LiveDashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      users: [],
      userMenu: false,
      menu: false,
      count: 0,
      pcount: 0,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.updateState = this.updateState.bind(this);
    this._createList = this._createList.bind(this);
  }
  async componentWillMount() {
    if ((this.state.user = JSON.parse(sessionStorage.getItem("user"))))
      this.state.isLogged = true;
    else {
      console.log("User not Logged in");
      this.state.isLogged = false;
    }

    //get data from storage
    const exam = JSON.parse(sessionStorage.getItem("exam1"));
    this.state.exam = exam;
    const user = JSON.parse(sessionStorage.getItem("user"));
    this.state.user = user;
    var tout, minutes_left;

    if (this.state.exam.window_start_date !== undefined) {
      this.state.exam.date = this.state.exam.window_start_date;
      this.state.exam.start_time = this.state.exam.window_start_time;
      var date = Date.parse(this.state.exam.date);
      var time = Date.parse(
        "1970-01-01T" + this.state.exam.start_time.toString() + "Z"
      );

      var now = Date.now();
      var passed = (now - date - time) / 60000;
      // if(passed > 0) {
      if (
        Math.floor(passed) <
        Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration)
      )
        minutes_left =
          Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration) -
          Math.floor(passed);
      else minutes_left = 0;
      //  else
      //    minutes_left=-1; //not started
      this.state.minutes_left = minutes_left;
      this.state.exam_status =
        minutes_left >
        Math.floor(this.state.exam.window_duration) +
          Math.floor(this.state.exam.duration)
          ? "Not Started"
          : minutes_left <= 0
          ? "Done"
          : "Ongoing";
      if (this.state.exam_status != "Done") {
        //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
        tout = setInterval(() => {
          this.state.exam_status =
            this.state.minutes_left-- >
            Math.floor(this.state.exam.window_duration) +
              Math.floor(this.state.exam.duration)
              ? "Not Started"
              : minutes_left < 0
              ? "Done"
              : "Ongoing";
        }, 60000);
      } else if (
        this.state.exam_status == "Done" ||
        this.state.minutes_left <= 0
      ) {
        clearInterval(tout);
        //return;
      }
    } else if (this.state.exam.date !== undefined) {
      //Time passed since exam and left
      const date = Date.parse(this.state.exam.date);
      const time = Date.parse(
        "1970-01-01T" + this.state.exam.start_time.toString() + "Z"
      );
      var now = Date.now();
      var passed = (now - date - time) / 60000;

      //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
      minutes_left = Math.floor(this.state.exam.duration) - Math.floor(passed);
      console.log(minutes_left);
      this.state.minutes_left = minutes_left;

      this.state.exam_status =
        minutes_left > Math.floor(this.state.exam.duration)
          ? "Not Started"
          : minutes_left <= 0
          ? "Done"
          : "Ongoing";
      //TODO: Minutes can be calculated from here. Put timeout or time interval?
      if (this.state.exam_status != "Done") {
        //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
        tout = setInterval(() => {
          this.state.exam_status =
            this.state.minutes_left-- > this.state.exam.duration
              ? "Not Started"
              : minutes_left < 0
              ? "Done"
              : "Ongoing";
        }, 60000);
      } else if (
        this.state.exam_status == "Done" ||
        this.state.minutes_left <= 0
      ) {
        clearInterval(tout);
        return;
      }
    } else {
      console.log("Exam Unscheduled");
    }

    //ask user data
    var api;
    if (exam) api = "user-api/useru/" + exam.id;
    else api = "user-api/userd/" + user.id; //new invig id changes. performance 2309

    const response = await Http.get(api);
    this.setState({ isLoaded: true, users: response.data });
  }
  //get the state every 30 seconds
  async componentDidMount() {
    //regulary monitor candidate states
    this.timerID = setInterval(() => {
      this.updateState();
    }, 15000);
  }
  componentWillUnmount() {
    // In Monitor Dashboard only 1 timer is there
    clearInterval(this.timerID);
  }
  async updateState(event) {
    const errorMsgElement = document.querySelector("span#errorMsg");
    //same as componentWillMount
    /*const exam = JSON.parse(sessionStorage.getItem('exam1'));
        this.state.exam=exam;*/
    //Get User State Every Minute
    if (this.state.exam_status != "Done") {
      var api;
      if (this.state.exam) api = "user-api/useru/" + this.state.exam.id;
      else {
        Http.defaults.headers.common["Authorization"] = this.state.user.token;
        api = "user-api/userd/" + this.state.user.id; //2309 To check token
      }
      const response = await Http.get(api);
      this.setState({ isLoaded: true, users: response.data });
      document.getElementById("last_updated").innerHTML = Date();
      this.state.exam_status =
        this.state.minutes_left > this.state.exam.duration
          ? "Not Started"
          : this.state.minutes_left <= 0
          ? "Done"
          : "Ongoing";
    } else if (
      this.state.exam_status == "Done" ||
      this.state.minutes_left <= 0
    ) {
      clearInterval(this.timerID);
      console.log("Exam Over. Stopped Requesting status");
      errorMsgElement.innerHTML = "Exam Over. Stopped Requesting status";
      alert("Exam Over");
    }
  }

  //take start time and add offset to it. Display localestring
  toLocal = (utctime) => {
    var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z"));
    //x+=this.state.user.timezone*60000;
    //return(x.toLocaleString().slice(16,24));
    return x.toLocaleTimeString();
  };
  toLocalTime = () => {
    console.log(this.props.exam.date);
    var x = new Date(
      this.props.exam.date + "T" + this.props.exam.start_time + "Z"
    );
    return x.toLocaleTimeString();
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    this.setState({ id: 0 });
  };

  AllowUser = (id, token) => {
    alert(id + " allowed");
    //Http.defaults.headers.common['Authorization'] = token;
    Http.put(
      "user-api/users/" + id,
      {
        status: "Verified",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      console.log("User updated Successfully");
    });
  };

  DenyUser = (id) => {
    alert(id + " denied");
    Http.put(
      "user-api/users/" + id,
      {
        status: "Denied",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      console.log("User updated Successfully");
    });
  };

  updateRemarks = (user) => {
    const errorMsgElement = document.querySelector("span#errorMsg");
    console.log(this.state.remarks);
    var api =
      "exam-api/examr/" +
      user.id +
      "/" +
      this.state.exam.id +
      "/" +
      this.state.remarks;
    Http.put(api)
      .then((res) => {
        console.log(res);
        console.log("Remarks given successfully");
        errorMsgElement.innerHTML = "Remarks given successfully";
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  toLocalLast = (last_login) => {
    if (last_login == "") return "Exam Not Given";
    var x = new Date(
      Date.parse(last_login.slice(0, 19).replace(" ", "T") + "Z")
    );
    return x.toLocaleString();
  };

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  isLive = (date_from_database) => {
    date_from_database = date_from_database.replace(/\s{2,}/g, " ");
    var x = new Date(
      Date.parse(date_from_database.slice(0, 19).replace(" ", "T") + "Z")
    );
    var date = new Date();
    //data from database
    //console.log("current date: " + date)

    var THREE_MIN = 3 * 60 * 1000;

    if (date - x > THREE_MIN) {
      return "Offline";
      console.log("Delayed by more than 3 min");
    } else {
      return "Online";
    }
  };

  _createList() {
    const live = this.state.users.filter(
      (user) => this.isLive(user.last_login) == "Online"
    );
    this.state.pcount = this.state.count;
    this.state.count = live.length;
    return live.map((user) => (
      <tr className="tb-member-item text-center">
        <td className="tb-member-info">
          <div className="user-card">
            <div className="user-avatar">
              <div className="custom-control custom-control-sm custom-checkbox d-none">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck2"
                />
                <label className="custom-control-label" htmlFor="customCheck2">
                  &nbsp;
                </label>
              </div>
              <div class="box ">
                <img
                  src={
                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                    user.reg_pic.split("'")[1]
                  }
                  class="regpic limsthumb"
                  style={{ display: user.status == "Verifying" ? "" : "none" }}
                  alt="reg pic"
                />
                <div
                  className="status-circle"
                  style={{
                    backgroundColor:
                      this.isLive(user.last_login) == "Offline"
                        ? "gray"
                        : "green",
                  }}
                ></div>
                <p
                  className="btn btn-icon"
                  onClick={() => this.AllowUser(user.id, user.token)}
                  style={{
                    display: user.status == "Verifying" ? "block" : "none",
                    color: "green",
                  }}
                >
                  <em className="icon ni ni-check"></em>
                </p>
                <img
                  src={
                    "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                    user.live_pic.split("'")[1]
                  }
                  style={{ top: user.status == "Verifying" ? "" : "0px" }}
                  class="livepic limsthumb"
                  alt="live"
                />
                <div
                  className="status-circle"
                  style={{
                    backgroundColor:
                      this.isLive(user.last_login) == "Offline"
                        ? "gray"
                        : "green",
                  }}
                ></div>
                <p
                  className="btn btn-icon"
                  onClick={() => this.DenyUser(user.id)}
                  style={{
                    display: user.status == "Verifying" ? "block" : "none",
                    borderRadius: "50%",
                    color: "red",
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </p>
              </div>
            </div>
            <div className="user-info" style={{ paddingLeft: "30px" }}>
              <a href={"#student" + user.id}>
                <span className="lead-text">
                  {user.first_name} {user.last_name}
                </span>
              </a>
              <span className="sub-text">STUD00{user.id}</span>
              <span className="sub-text">{user.email}</span>
              <span className="sub-text contact d-md-none">{user.school}</span>
              <span className="sub-text contact d-md-none">{user.status}</span>
              <span className="sub-text date d-md-none">
                {this.toLocalLast(user.last_login)}
              </span>
              <span className="sub-text contact d-md-none">{user.cred}</span>
              <input
                type="text"
                name="remarks"
                className="sub-text d-md-none custom-control-input"
                id="customCheck2"
                placeholder="Remarks"
                onChange={this.handleChange}
              />

              <span className="sub-text contact d-md-none">
                <a
                  href="#errorMsg"
                  className="btn btn-outline-secondary"
                  onClick={() => this.updateRemarks(user)}
                >
                  Send
                </a>
              </span>
            </div>
          </div>
        </td>
        <td className="tb-member-type tb-col-sm d-none">
          <span>{user.class}</span>
        </td>
        <td className="tb-member-status tb-col-md d-none text-center">
          <span>{user.status}</span>
        </td>
        <td className="tb-member-action tb-col-md d-none text-center">
          <span>{this.toLocalLast(user.last_login)}</span>
        </td>
        <td className="tb-member-cred tb-col-md d-none text-center">
          <span>{user.cred}</span>
        </td>
        <td
          className="tb-member-remarks tb-col-md d-flex text-center"
          style={{ display: this.state.user.role == "invig" ? "" : "none" }}
        >
          <input
            name="remarks"
            type="text"
            placeholder="Remarks"
            onChange={this.handleChange}
          />
          <a
            href="#"
            className="btn btn-icon btn-outline-primary"
            onClick={() => this.updateRemarks(user)}
          >
            <em className="ni ni-send"></em>
          </a>
        </td>
        <td className="tb-tnx-action d-none text-center">
          <div className="dropdown">
            <a
              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right dropdown-menu-xs"
              style={{}}
            >
              <ul className="link-list-plain">
                <li>
                  <a href="#">View</a>
                </li>
                <li>
                  <a href="#">Edit</a>
                </li>
                <li>
                  <a href="#">Remove</a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    if (this.state.isLogged) {
      const show = this.state.menu ? "active" : "";
      const showMenu = this.state.menu ? "content-active" : "";
      const usershow = this.state.userMenu ? "show" : "";
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Dashboard | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link
            id="skin-default"
            rel="stylesheet"
            href="assets/css/theme.css?ver=1.2.0"
          />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img
                            className="logo-dark logo-img"
                            src="images/monitor-exam-logo.png"
                            srcSet="images/monitor-exam-logo-2x.png 2x"
                            alt="logo-dark"
                          />
                        </a>
                      </div>
                      {/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li
                            onClick={this.toggleUserMenu}
                            className={"dropdown user-dropdown " + usershow}
                          >
                            <a
                              href="#"
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                  <img
                                    src={
                                      "https://d1bumj8k6ax755.cloudfront.net/uploads/" +
                                      this.state.user.reg_pic.split("'")[1]
                                    }
                                    class="regpic "
                                    alt="reg"
                                    style={{
                                      transform: "scale(0.5)",
                                      left: "6px",
                                    }}
                                  />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">
                                  {this.state.user.first_name}{" "}
                                  {this.state.user.last_name}
                                </div>
                              </div>
                            </a>
                            <div
                              className={
                                "dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " +
                                usershow
                              }
                            >
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>
                                      {this.state.user.first_name.charAt(0)}
                                      {this.state.user.last_name.charAt(0)}
                                    </span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">
                                      {this.state.user.first_name}{" "}
                                      {this.state.user.last_name}
                                    </span>
                                    <span className="sub-text">
                                      {this.state.user.email}}
                                    </span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#">
                                      <em className="icon ni ni-setting" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li>
                                    <a href="\viewprofile">
                                      <em className="icon ni ni-user-alt" />
                                      <span>View Profile</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/security">
                                      <em className="icon ni ni-setting-alt" />
                                      <span>Security Setting</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#">
                                      <em className="icon ni ni-activity-alt" />
                                      <span>Login Activity</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <Logout />
                                </ul>
                              </div>
                            </div>
                          </li>
                          {/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a
                              href="#"
                              className={
                                "toggle nk-quick-nav-icon mr-n1 " +
                                (this.state.menu ? "active" : "")
                              }
                              data-target="sideNav"
                            >
                              <em className="icon ni ni-menu" />
                            </a>
                          </li>
                        </ul>
                        {/* .nk-quick-nav */}
                      </div>
                      {/* .nk-header-tools */}
                    </div>
                    {/* .nk-header-wrap */}
                  </div>
                  {/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content ">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div
                        className={"nk-aside " + showMenu}
                        data-content="sideNav"
                        data-toggle-overlay="true"
                        data-toggle-screen="lg"
                        data-toggle-body="true"
                      >
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li
                              className={
                                "nk-menu-item " +
                                (this.state.user.role == "invig"
                                  ? "active"
                                  : "noactive")
                              }
                            >
                              <a href="\newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-users" />
                                </span>
                                <span className="nk-menu-text">
                                  Student Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item ">
                                  <a
                                    href="\livestudentdash"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item ">
                                  <a
                                    href="\newdashboard"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      All Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\testdash" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-file-docs" />
                                </span>
                                <span className="nk-menu-text">
                                  Exam Dashboard
                                </span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item">
                                  <a
                                    href="\newmonidash"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Registered Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item active">
                                  <a href="#" className="nk-menu-link">
                                    <span className="nk-menu-text">
                                      Live Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="#studentsneedingattention"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Suspicious Students
                                    </span>
                                  </a>
                                </li>
                                <li className="nk-menu-item">
                                  <a
                                    href="\newcompleted"
                                    className="nk-menu-link"
                                  >
                                    <span className="nk-menu-text">
                                      Completed Students
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon">
                                  <em className="icon ni ni-signout" />
                                </span>
                                <span
                                  onClick={() => sessionStorage.clear()}
                                  className="nk-menu-text"
                                >
                                  Log Out
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav">
                            <em className="icon ni ni-cross" />
                          </a>
                        </div>
                        {/* .nk-aside-close */}
                      </div>
                      {/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title">
                                  Monitor Dashboard
                                </h5>
                                <br />
                                <div className="row">
                                  <div className="col-sm-4">
                                    <p className="exam-name mb-1">
                                      ID: EXAM00{this.state.exam.id}
                                    </p>
                                    <h6 className="text-primary pl-4">
                                      {this.state.exam.name}
                                    </h6>
                                  </div>
                                  <div className="col-sm-4">
                                    <p className="attendance-marked">
                                      Status: {this.state.exam_status}
                                    </p>
                                    <progress
                                      className="d-none"
                                      value={
                                        this.state.exam.duration +
                                        this.state.exam.window_duration -
                                        this.state.minutes_left
                                      }
                                      max={this.state.exam.duration}
                                      style={{
                                        color: "#6576ff",
                                        backgroundColor: "#f5f6fa",
                                      }}
                                      width="350px"
                                      height="25px"
                                    >
                                      {this.state.exam.duration +
                                        this.state.exam.window_duration -
                                        this.state.minutes_left}
                                    </progress>
                                    <div class="progress progress-lg d-none">
                                      <div
                                        class="progress-bar"
                                        data-progress={
                                          this.state.exam.duration +
                                          this.state.exam.window_duration -
                                          this.state.minutes_left
                                        }
                                        style={{
                                          width: `${
                                            ((this.state.exam.duration +
                                              this.state.exam.window_duration -
                                              this.state.minutes_left) /
                                              (this.state.exam.duration +
                                                this.state.exam
                                                  .window_duration)) *
                                            100
                                          }%`,
                                        }}
                                      >
                                        {this.state.exam.duration -
                                          this.state.minutes_left}{" "}
                                        minutes
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <div
                                      className="text-right"
                                      style={{ color: "#6576ff" }}
                                    >
                                      Time left :{" "}
                                      <CountdownTimer
                                        initialMinutes={this.state.minutes_left}
                                      />
                                      {/*Time left : {this.state.minutes_left} minutes<span id="timer" />*/}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="nk-block-des card card-bordered pr-20 pl-20 d-none"
                                  style={{ backgroundColor: "#f3f6ff" }}
                                >
                                  <p>Monitor the students in exam room.</p>
                                  <p className="exam-name mb-1">
                                    ID: EXAM00{this.state.exam.id}
                                  </p>
                                  <p className="attendance-marked">
                                    Start Time:{" "}
                                    {this.state.exam.date !== undefined
                                      ? Intl.DateTimeFormat().format(
                                          Date.parse(this.state.exam.date) +
                                            Date.parse(
                                              "1970-01-01T" +
                                                this.state.exam.start_time.toString() +
                                                "Z"
                                            )
                                        )
                                      : ""}
                                    ,{" "}
                                    {this.state.exam.start_time !== undefined
                                      ? this.toLocal(this.state.exam.start_time)
                                      : "Exam not Scheduled"}
                                  </p>
                                  <p className="attendance-marked">
                                    Status: {this.state.exam_status}
                                  </p>
                                  <p className="attendance-marked">
                                    {" "}
                                    Time left :{" "}
                                    <CountdownTimer
                                      initialMinutes={this.state.minutes_left}
                                    />
                                  </p>
                                  <p id="last_updated d-none"></p>
                                  <p id="">
                                    {this.state.count - this.state.pcount > 0
                                      ? this.state.count -
                                        this.state.pcount +
                                        " Students Entered"
                                      : this.state.pcount -
                                        this.state.count +
                                        " Students Left"}
                                  </p>
                                  <p>
                                    <span
                                      id="timeMsg"
                                      style={{ display: "block" }}
                                    >
                                      {" "}
                                    </span>
                                  </p>
                                  <p>
                                    <em>
                                      <span
                                        id="errorMsg"
                                        style={{ display: "block" }}
                                      ></span>
                                    </em>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* .nk-block-head */}
                          {/* <p><a href="#chat">Chat</a> with Student</p>*/}
                          <div className="nk-block row">
                            <div className="card col-sm-9">
                              <table
                                className="table table-member"
                                id="myTable"
                              >
                                <thead className="tb-member-head thead-light">
                                  <tr className="tb-member-item ">
                                    <th className="tb-member-info text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Name
                                      </span>
                                      <span className="overline-title d-md-none">
                                        Details
                                      </span>
                                      <span className="overline-title d-md-none" />
                                    </th>
                                    <th className="tb-member-type tb-col-sm text-center">
                                      <span className="overline-title">
                                        School
                                      </span>
                                    </th>
                                    <th className="tb-member-role tb-col-md text-center">
                                      <span className="overline-title">
                                        Status
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Last Seen
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Cred Score
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none">
                                        Remarks
                                      </span>
                                    </th>
                                    <th className="tb-member-action text-center">
                                      <span className="overline-title d-md-inline-block d-none"></span>
                                    </th>
                                    <th className="tb-tnx-action d-none text-center">
                                      <span>&nbsp;</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="tb-member-body">
                                  <>
                                    {this._createList()}
                                    {/*this.state.users.filter(user => (this.isLive(user.last_login) === 'Online')).map(user=>( 
                                    <tr className="tb-member-item">
                                        <td className="tb-member-info">
                                            <div className="user-card">
                                                <div className="user-avatar">
                                                    <div className="custom-control custom-control-sm custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                        <label className="custom-control-label" htmlFor="customCheck2">&nbsp;</label>
                                                    </div>
					        <div class="box ">
                                                    <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/'+user.reg_pic.split("'")[1]} class="regpic limsthumb" style={{display:user.status=="Verifying"?"":"none"}} alt="reg pic"/>
                                              <div className='status-circle' style={{ backgroundColor: (this.isLive(user.last_login) == "Offline") ? "gray" : "green" }}></div>
                                                    <p className="btn btn-icon" onClick={()=>this.AllowUser(user.id,user.token)} style={{display:user.status=="Verifying"?"block":"none",color:"green"}}><em className="icon ni ni-check"></em></p>
                                                    <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/'+user.live_pic.split("'")[1]} style={{top:user.status=="Verifying"?"":"0px"}} class="livepic limsthumb" alt="live"/>
                                              <div className='status-circle' style={{ backgroundColor: (this.isLive(user.last_login) == "Offline") ? "gray" : "green" }}></div>
                                                    <p className="btn btn-icon" onClick={()=>this.DenyUser(user.id)} style={{display:user.status=="Verifying"?"block":"none",borderRadius:"50%",color:"red"}}><em className="icon ni ni-cross"></em></p>
                                                </div>
                                                </div>
                                                <div className="user-info" style={{ paddingLeft: "30px" }}>
                                                    <a href={"#student"+user.id}><span className="lead-text">{user.first_name} {user.last_name}</span></a>
                                                    <span className="sub-text">STUD00{user.id}</span>
                                                    <span className="sub-text">{user.email}</span>
                                                    <span className="sub-text contact d-md-none">{user.status}</span>
                                                    <span className="sub-text date d-md-none">{this.toLocalLast(user.last_login)}</span>
                                                    <span className="sub-text contact d-md-none">{user.cred}</span>
                                                    <input type="text" name="remarks" className="sub-text d-md-none custom-control-input" id="customCheck2" placeholder="Remarks" onChange={this.handleChange}/>
                                                    
                                                    <span className="sub-text contact d-md-none"><a href="#errorMsg" className="btn btn-outline-secondary" onClick={()=>this.updateRemarks(user)}>Send</a></span>
                                                </div>
                                            </div>
                                        </td>
                                      <td className="tb-member-type tb-col-sm d-none">
                                        <span>{user.class}</span>
                                      </td>
                                      <td className="tb-member-status tb-col-md d-none">
                                        <span>{user.status}</span>
                                      </td>
                                      <td className="tb-member-action tb-col-md d-none">
                                        <span>{this.toLocalLast(user.last_login)}</span>
                                      </td>
                                      <td className="tb-member-cred tb-col-md d-none">
                                        <span>{user.cred}</span>
                                      </td>
				      <td className="tb-col-md d-none">
				      </td>
                                      <td  className="tb-member-remarks tb-col-md d-none" style={{display:this.state.user.role=='invig'?"":"none"}} >
                                        <input name="remarks" type="text" placeholder="Remarks" onChange={this.handleChange} />
                                        <a href="#" className="btn btn-icon btn-outline-secondary" onClick={()=>this.updateRemarks(user)}><em className="ni ni-icon ni-send"></em></a>
                                      </td>
                                      <td className="tb-tnx-action">
                                        <div className="dropdown">
                                          <a className="text-soft dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown" aria-expanded="false"><em className="icon ni ni-more-h" /></a>
                                          <div className="dropdown-menu dropdown-menu-right dropdown-menu-xs" style={{}}>
                                            <ul className="link-list-plain">
                                              <li><a href="#">View</a></li>
                                              <li><a href="#">Edit</a></li>
                                              <li><a href="#">Remove</a></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                      </tr>
                                    ))
                          */}
                                  </>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-sm-3" id="chat">
                              <Pubnub users={this.state.users} />
                            </div>
                            <div
                              className="nk-block-des card card-bordered pr-20 pl-20  col-sm-9"
                              style={{ backgroundColor: "#f3f6ff" }}
                            >
                              <p className="attendance-marked">
                                Number of Live Students: {this.state.count}
                              </p>
                              <p id="">
                                {this.state.count - this.state.pcount > 0
                                  ? this.state.count -
                                    this.state.pcount +
                                    " Students Entered"
                                  : this.state.pcount -
                                    this.state.count +
                                    " Students Left"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nk-content-body"
                          id="studentsneedingattention"
                        >
                          <div className="nk-content-wrap">
                            <div className="nk-block-head nk-block-head-lg">
                              <div className="nk-block-between-md g-4">
                                <div className="nk-block-head-content">
                                  <h5 className="nk-block-title">
                                    Students Needing Attention
                                  </h5>
                                  <div className="nk-block-des">
                                    <p>
                                      <a href="#chat">Chat</a> with Student
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* .nk-block-head */}
                            <div className="nk-block">
                              <div className="row student-dashboard">
                                <>
                                  {this.state.users
                                    .filter(
                                      (user) =>
                                        this.isLive(user.last_login) ==
                                          "Online" &&
                                        (user.status.includes("Away") ||
                                          user.status.includes("Looking") ||
                                          user.status.includes("Distracted") ||
                                          user.status.includes("Doubt"))
                                    )
                                    .map((user) => (
                                      <NewStudentCard user={user} />
                                    ))}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* footer @e */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* content @e */}
              </div>
              {/* wrap @e */}
            </div>
            {/* main @e */}
          </div>
          {/*footer*/}
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
    } else {
      return <NewLogin />;
    }
  }
}
export default LiveDashboard;
